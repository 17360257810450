<template>
  <div>
    <a-row>
      <a-col span="12">
        <a-descriptions
          title="VPDN分组信息"
          :column="1"
          class="simiot-descriptions simiot-descriptions-vpdn"
        >
          <a-descriptions-item label="VPDN分组ID">
            {{ vpdn.vpdn_id }}
          </a-descriptions-item>

          <a-descriptions-item label="VPDN分组名称">

            {{ vpdn.name }}
          </a-descriptions-item>

          <a-descriptions-item style=" white-space:pre" label="VPDN IP地址">
            <div v-html="vpdn.ips" />
          </a-descriptions-item>

          <a-descriptions-item label="描述">
            <div> {{ vpdn.description }}</div>
          </a-descriptions-item>

        </a-descriptions>
      </a-col>

      <a-col span="12">
        <a-descriptions
          title="操作历史"
          :column="1"
          class="simiot-descriptions"
        />

        <operation-history-list
          :reference-id="this.vpdnManageId"
          reference-type="Vpdn"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { findVpdn } from '@/api/vpdn'
import OperationHistoryList from '@/views/operation_histories'

export default {
  name: 'ShowVpdn',
  components: { OperationHistoryList },
  data() {
    return {
      vpdn: {}
    }
  },
  computed: {
    vpdnManageId() {
      return parseInt(this.$route.params.vpdn_manage_id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findVpdn(this.vpdnManageId).then((res) => {
        if (res.code === 0) {
          var data = res.data
          data.ips = JSON.parse(data.ips).join('<br/>')
          this.vpdn = data
        }
      })
    }
  }
}
</script>
<style  lang="less">
.simiot-descriptions-vpdn .ant-descriptions-item-label {
    width: 120px !important;
}

.simiot-descriptions-vpdn .ant-descriptions-item-content {
  width: calc(100% - 120px) !important;
}

.simiot-descriptions-vpdn .ant-descriptions-item{
  display: flex
}
</style>
