import request from '@/utils/request'
// 查询vpdn分组
export function findVpdns(params) {
  return request({
    url: `/agents/vpdn_manage`,
    method: 'get',
    params: params
  })
}

// 创建vpdn分组
export function createVpdn(data) {
  return request({
    url: `/agents/vpdn_manage`,
    method: 'post',
    data
  })
}

// 批量更新vpdn有效性
export function batchUpdateVpdnsEffective(ids, effective) {
  const data = { ids: ids, effective: effective }
  return request({
    url: `/agents/vpdn_manage/effective`,
    method: 'patch',
    data
  })
}

// 查询单个vpdn表单值
export function findVpdnForm(id) {
  return request({
    url: `/agents/vpdn_manage/${id}/form`,
    method: 'get'
  })
}

// 更新vpdn
export function updateVpdn(data) {
  return request({
    url: `/agents/vpdn_manage/${data.id}`,
    method: 'put',
    data
  })
}

// 查询单个vpdn信息
export function findVpdn(id) {
  return request({
    url: `/agents/vpdn_manage/${id}`,
    method: 'get'
  })
}

// 查询vpdn分组所有
export function findVpdnsAll(params) {
  return request({
    url: `/agents/vpdns`,
    method: 'get',
    params: params
  })
}
